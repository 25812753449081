// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ql-snow .ql-picker.ql-font .ql-picker-label[data-value]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value]::before {
  content: attr(data-value) !important;
}

.ql-editor {
  height: 250px;
}

.ql-toolbar {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.ql-container {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.ql-picker-label {
  overflow: hidden;
}

.ql-picker-item[data-value="Ariel"] {
  font-family: "Arial", sans-serif;
}

.ql-picker-item[data-value="Courier New"] {
  font-family: "Courier New", sans-serif;
}

.ql-picker-item[data-value="Georgia"] {
  font-family: "Georgia", serif;
}

.ql-picker-item[data-value="Lucida Sans Unicode"] {
  font-family: "Lucida Sans Unicode", sans-serif;
}

.ql-picker-item[data-value="Tahoma"] {
  font-family: "Tahoma", sans-serif;
}

.ql-picker-item[data-value="Times New Roman"] {
  font-family: "Times New Roman", serif;
}

.ql-picker-item[data-value="Verdana"] {
  font-family: "Verdana", sans-serif;
}
`, "",{"version":3,"sources":["webpack://./corehq/apps/hqwebapp/static/hqwebapp/js/components/quill.css"],"names":[],"mappings":"AAAA;;EAEE,oCAAoC;AACtC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;AAC9B;;AAEA;EACE,8BAA8B;EAC9B,+BAA+B;AACjC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,sCAAsC;AACxC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,8CAA8C;AAChD;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".ql-snow .ql-picker.ql-font .ql-picker-label[data-value]::before,\n.ql-snow .ql-picker.ql-font .ql-picker-item[data-value]::before {\n  content: attr(data-value) !important;\n}\n\n.ql-editor {\n  height: 250px;\n}\n\n.ql-toolbar {\n  border-top-left-radius: 3px;\n  border-top-right-radius: 3px;\n}\n\n.ql-container {\n  border-bottom-left-radius: 3px;\n  border-bottom-right-radius: 3px;\n}\n\n.ql-picker-label {\n  overflow: hidden;\n}\n\n.ql-picker-item[data-value=\"Ariel\"] {\n  font-family: \"Arial\", sans-serif;\n}\n\n.ql-picker-item[data-value=\"Courier New\"] {\n  font-family: \"Courier New\", sans-serif;\n}\n\n.ql-picker-item[data-value=\"Georgia\"] {\n  font-family: \"Georgia\", serif;\n}\n\n.ql-picker-item[data-value=\"Lucida Sans Unicode\"] {\n  font-family: \"Lucida Sans Unicode\", sans-serif;\n}\n\n.ql-picker-item[data-value=\"Tahoma\"] {\n  font-family: \"Tahoma\", sans-serif;\n}\n\n.ql-picker-item[data-value=\"Times New Roman\"] {\n  font-family: \"Times New Roman\", serif;\n}\n\n.ql-picker-item[data-value=\"Verdana\"] {\n  font-family: \"Verdana\", sans-serif;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
